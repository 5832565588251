import { useState, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import amplitude from "amplitude-js";
import Grid, { GridElem } from "../../Grid/Grid";
import OnboardingFormDescription from "../OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import OnboardingFormTextInput from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import OnboardingFormSelect from "../OnboardingFormComponents/OnboardingFormSelect/OnboardingFormSelect";
import { statesArray } from "../../../helpers/StatesArray";
import { IOnboardingForm } from "../OnboardingForm";
import Text from "../../Text/Text";
import sessionCheck from "../../../helpers/sessionCheck";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";
import OnboardingFormTextInputPhone from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInputPhone";
import DescriptionInfo from "../../DescriptionInfo/DescriptionInfo";
import NumberOfStep from "../../NumberOfStep/NumberOfStep";
import styles from "./OnboardingFormSectionAddress.module.scss";
import { useMediaQuery } from "react-responsive";
import { partnersLabel, partnersName } from "../../../utils/partnerList";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";

interface IOnboardingFormSectionAddress extends IOnboardingFormSection {
  userEmail: IOnboardingForm["userEmail"];
  uid?: IOnboardingForm["uid"];
  patientData?: any;
}

type FormValuesT = {
  phone: string;
  stateForCare: string;
  zipCode: string;
};

const OnboardingFormSectionAddress = (props: IOnboardingFormSectionAddress) => {
  const {
    formData,
    setFormData,
    userEmail,
    step,
    setLoginMessage,
    setNextStep,
    setFirstStep,
    uid,
    patientData,
    partner,
  } = props;

  const history = useHistory();

  const [partnerAvailableState, setPartnerAvailableState] = useState(true);
  const [prescriptionAvailableStates, setPrescriptionAvailableStates] = useState<string[]>([]);
  const [insuranceAvailableStates, setInsuranceAvailableStates] = useState<string[]>([]);
  const [partnerAvailableStates, setPartnerAvailableStates] = useState<string[]>([]);
  const [showStateWarningMessage, setShowStateWarningMessage] = useState(false);
  const [showInsuranceMessage, setShowInsuranceMessage] = useState(false);
  const [showNonUSMessage, setShowNonUSMessage] = useState(true);
  const [formValues, setFormValues] = useState<FormValuesT>({
    phone: formData?.phone || patientData?.phoneNumber || "",
    stateForCare: formData?.stateForCare || patientData?.stateForCare || "",
    zipCode: formData?.zipCode || patientData?.zipCode || "",
  });

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const { runTracking } = useGATrackingForSpecificPartner();

  const checkStateAvailability = async (value: string) => {
    setShowStateWarningMessage(false);

    if (!value) {
      return;
    }

    const currentState = value && value.trim();

    setShowNonUSMessage(!currentState);
    setShowInsuranceMessage(false);
    if (insuranceAvailableStates.includes(currentState)) {
      setShowInsuranceMessage(true);
    } else if (!prescriptionAvailableStates.includes(currentState)) {
      setShowStateWarningMessage(true);
    }
  };

  const onPhoneDataSubmit = async (values: FormValuesT) => {
    const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);
    const { stateForCare, zipCode, phone } = values;

    if (!session) {
      return;
    }
    const stateForCareValue = statesArray.find((item) => item.label === stateForCare)?.value;

    const firestore = new FirebaseApi().firebaseFirestore();

    setFormData({
      ...formData,
      stateForCare: stateForCareValue ?? stateForCare.trim(),
      zipCode: zipCode.trim(),
      phone: phone.trim(),
      state: stateForCareValue ?? stateForCare.trim(),
    });

    await firestore.updatePatient({
      data: {
        phoneNumber: phone?.trim(),
        uid: uid || formData?.uid,
        zipCode: zipCode.trim(),
        stateForCare: stateForCareValue ?? stateForCare.trim(),
        state: stateForCareValue ?? stateForCare.trim(),
      },
    });

    setNextStep();

    try {
      const functions = new FirebaseApi().firebaseFunctions();

      amplitude.getInstance().logEvent("onboarding_v2_phone_state_click", {
        phoneNumber: values.phone,
        state: values.stateForCare ? values.stateForCare.trim() : "",
      });

      // This event is for one specific partner, Theranica
      runTracking(partnersName.THERANICA, "neura_phone-state");

      isNotExcludedFromTracking(formData?.email || userEmail, () => {
        // @ts-ignore
        window?.dataLayer.push({
          event: "onboarding_v2_phone_state_click",
        });
      });

      functions.updateHubspotContact("phone", {
        email: formData?.email || userEmail,
        eventValue: values.phone,
      });

      functions.updateHubspotContact("zip", {
        email: formData?.email || userEmail,
        eventValue: values.zipCode,
      });

      functions.updateHubspotContact("state", {
        email: formData?.email || userEmail,
        eventValue: values.stateForCare ? values.stateForCare.trim() : "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const foundValueForState = () => {
    if (formData?.stateForCare) {
      const foundState = statesArray.find((item) => item.value === formData.stateForCare);
      if (foundState) {
        return foundState.label;
      }
      return "";
    } else if (patientData?.stateForCare) {
      const foundState = statesArray.find((item) => item.value === patientData.stateForCare);
      if (foundState) {
        return foundState.label;
      }
      return "";
    }

    return "";
  };

  const fetchStateAvailablePartner = async () => {
    const stateName = formValues?.stateForCare;
    if (!stateName) return;
    setPartnerAvailableState(
      partnerAvailableStates.includes(stateName) || partnerAvailableStates.length === 0
    );
  };

  // prefetch available states and available insurance states
  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    const getStates = async () => {
      const availableStates = await firestore.getPrescriptionAvailableStates();
      setPrescriptionAvailableStates(availableStates.stateList);
      const insuranceAvailableStates = await firestore.getInsuranceAvailableStates();
      setInsuranceAvailableStates(insuranceAvailableStates.stateList);
    };
    getStates();
  }, []);

  // prefetch available states for partner
  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    const getStatesForPartner = async () => {
      const data = await firestore.getPartnerData(partner || "d2c");
      setPartnerAvailableStates(data ? data.stateList : []);
    };
    getStatesForPartner();
  }, [partner]);

  useEffect(() => {
    fetchStateAvailablePartner();
    setFormData({
      ...formData,
      zipCode: formValues.zipCode,
      stateForCare: formValues.stateForCare ? formValues.stateForCare.trim() : "",
      phone: formValues.phone,
      state: formValues.stateForCare ? formValues.stateForCare.trim() : "",
    });
  }, [formValues]);

  const labelToUpperCase = (item: string) => {
    let label = "";
    Object.keys(partnersLabel).forEach((key: any) => {
      if (partnersName[key] === item) {
        label = partnersLabel[key];
      }
    });
    return label;
  };

  const generateState = () => {
    const currentState = statesArray.find((item) => item.value === formValues.stateForCare);
    return currentState?.label;
  };

  return (
    <>
      <Formik
        initialValues={{
          phone: formData?.phone || patientData?.phoneNumber || "",
          stateForCare: foundValueForState(),
          zipCode: formData?.zipCode || patientData?.zipCode || "",
        }}
        onSubmit={onPhoneDataSubmit}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            .matches(/^[0-9]{3}[-][0-9]{3}[-][0-9]{4}$/, "Phone number is invalid.")
            .required("Phone number is required."),
          stateForCare: Yup.string().min(1).required("State is required."),
          zipCode: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits.")
            .length(5, "ZIP Code must be valid.")
            .required("ZIP Code is required."),
        })}
        validateOnChange
      >
        {({ isValid, isSubmitting }) => (
          <Form
            data-testid="addressForm"
            className={cn("d-flex flex-column h-100", styles.form)}
            id="address_state"
            onChange={(e: React.ChangeEvent<HTMLFormElement>) => onChange(e)}
          >
            <GridElem
              className={cn({
                [styles.infoBox]: !smallScreen,
                [styles.infoBoxMobile]: smallScreen,
              })}
            >
              <DescriptionInfo
                text={
                  <>
                    Let’s get your contact details <br /> so we can streamline your <br /> care
                    needs efficiently
                  </>
                }
              />
            </GridElem>

            <div className="d-flex flex-column align-items-lg-end mb-xlarge mb-3">
              <Grid className={styles.wrapper} rowGap={0}>
                <GridElem>
                  <Field
                    inputMode="numeric"
                    label="Cell phone number"
                    name="phone"
                    component={OnboardingFormTextInputPhone}
                    placeholder="XXX-XXX-XXXX"
                    validateOnChange
                  />
                </GridElem>

                <GridElem>
                  <Field
                    checkStateAvailability={checkStateAvailability}
                    label="State"
                    name="stateForCare"
                    component={OnboardingFormSelect}
                    options={statesArray}
                    setFormValues={setFormValues}
                    validateOnChange
                  />
                  {partner && !partnerAvailableState && (
                    <Text variant="medium" color="alert" className={cn(styles.stateText)}>
                      We are currently unable to accept {labelToUpperCase(partner)} patients in{" "}
                      {generateState()}. Please contact{" "}
                      <a
                        href="mailto:support@neurahealth.co"
                        target="_blank"
                        className="link-error"
                        rel="noreferrer"
                      >
                        support@neurahealth.co
                      </a>{" "}
                      for further assistance.
                    </Text>
                  )}

                  {!partner && (
                    <>
                      <Text
                        variant="medium"
                        color="green"
                        className={cn(styles.stateText, {
                          hidden: !showNonUSMessage,
                        })}
                      >
                        Not in the US? Click{" "}
                        <a
                          target="_blank"
                          className="link"
                          rel="noreferrer"
                          href={`https://www.neurahealth.co/faq/what-geographic-locations-does-neura-currently-serve`}
                        >
                          here
                        </a>
                        .
                      </Text>
                      <Text
                        variant="medium"
                        color="green"
                        className={cn(styles.stateText, {
                          hidden: !showInsuranceMessage,
                        })}
                      >
                        For visit fees, we now accept some insurance plans in your state. Learn more{" "}
                        <a
                          target="_blank"
                          className="link"
                          rel="noreferrer"
                          href={`https://neurahealth.co/insurance`}
                        >
                          here
                        </a>
                        .
                      </Text>
                      <Text
                        variant="medium"
                        color="green"
                        className={cn(styles.stateText, {
                          hidden: !showStateWarningMessage,
                        })}
                      >
                        In your state, Neura recommends prescriptions to your primary care provider,
                        who would need to write the prescription for you. In most cases, they are
                        willing to do this. More information{" "}
                        <a
                          target="_blank"
                          className="link"
                          rel="noreferrer"
                          href={`https://www.neurahealth.co/faq/what-geographic-locations-does-neura-currently-serve`}
                        >
                          here
                        </a>
                        .
                      </Text>
                    </>
                  )}
                </GridElem>

                <GridElem size={6} md={5}>
                  <Field
                    label="ZIP Code"
                    name="zipCode"
                    component={OnboardingFormTextInput}
                    validateOnChange
                  />
                </GridElem>
              </Grid>
            </div>

            {smallScreen && (
              <div>
                <OnboardingFormDescription
                  className={styles.text}
                  text={
                    <span>
                      By providing your phone number, you consent to receive texts from or on behalf
                      of Neura Health about your care.
                    </span>
                  }
                />
              </div>
            )}

            <div className="d-flex justify-content-center">
              <OnboardingFormSubmit
                title="Next"
                disabled={!isValid || !partnerAvailableState}
                isSubmitting={isSubmitting}
              />
            </div>

            {!smallScreen && (
              <div className={styles.bottomText}>
                <OnboardingFormDescription
                  className={styles.text}
                  text={
                    <span>
                      By providing your phone number, you consent to receive texts from or on behalf
                      of Neura Health about your care.
                    </span>
                  }
                />
              </div>
            )}

            <NumberOfStep step={step} />
          </Form>
        )}
      </Formik>
    </>
  );
};
export default OnboardingFormSectionAddress;
