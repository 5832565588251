import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Button.module.scss";
import cn from "classnames";

interface IButton {
  tag?: "a" | "button";
  title?: React.ReactNode;
  disabled?: boolean;
  to?: string;
  icon?: React.ReactNode;
  className?: string;
  variant?: "primary" | "secondary" | "alternate";
  shape?: "rounded" | "squared";
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  children?: React.ReactNode;
}

const Button = ({
  tag = "button",
  title,
  to = "/",
  icon,
  className,
  variant = "primary",
  shape = "rounded",
  onClick,
  disabled = false,
  type = "button",
  children,
}: IButton) => {
  if (tag === "a") {
    return (
      <NavLink
        onClick={onClick}
        className={cn(
          styles.element,
          "d-inline-flex justify-content-center",
          styles[variant],
          styles[shape],
          className
        )}
        exact
        to={{ pathname: to, search: window.location.search }}
      >
        {title}
        {children}
      </NavLink>
    );
  }

  return (
    <button
      onClick={onClick}
      className={cn(
        styles.element,
        "d-inline-flex justify-content-center",
        styles[variant],
        styles[shape],
        disabled && styles["disabled"],
        className
      )}
      disabled={disabled}
      type={type}
    >
      {icon}
      {title}
      {children}
    </button>
  );
};
export default Button;
